import React from 'react';
import Form from '../components/Form';

function Wip() {
  return (
    <Form
      from="wip"
      title="work in progress"
      text="leave your email and we’ll let you know when this is out"
    />
  );
}
export default Wip;
