import React from 'react';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';
import Arrow from '../svg/arrow.svg';
import Seo from './SEO';

function Emails({ from, title, text, itemName }) {
  const [pop] = useSound(Switch);
  const historyBack = () => {
    window.history.back();
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-primary wip">
      <Seo pageTitle={title} />
      <div className="flex flex-col items-center justify-center max-w-xl text-white">
        <div className="px-24 py-12 border border-b-0 border-white">
          <h1 className="font-serif text-4xl font-extrabold leading-tight text-center">
            {title}
          </h1>
          <br />
          <p className="mt-2 mb-8 text-lg text-center ">{text}</p>
        </div>
        <div className="w-full -mt-px -mb-px">
          <form
            name={from}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/thankyou"
            className="flex flex-row"
          >
            <input type="hidden" name="form-name" value={from} />
            <div className="w-full">
              {itemName ? (
                <input
                  type="text"
                  name="itemName"
                  placeholder="item name"
                  className="-mt-px -mb-px border-l-0 email focus:outline-none"
                  value={itemName}
                />
              ) : null}

              <input
                type="email"
                htmlFor="email address"
                placeholder="email"
                name="email"
                className="-mt-px bg-transparent border-l-0 email focus:outline-none"
              />
            </div>
            <button
              onClick={pop}
              type="submit"
              className="relative p-6 -mt-px -ml-px border border-white footer-btn focus:outline-none"
              aria-label="Submit button"
            >
              <Arrow />
            </button>
          </form>
        </div>
        <div className="flex flex-col justify-center w-full border border-t-0 border-white align-center">
          <p className="my-16 text-xl text-center ">or</p>
          <br />
        </div>
        <button className="-mt-2 inverse-btn" onClick={historyBack}>
          <b>take me back</b>
        </button>
      </div>
    </div>
  );
}

export default Emails;
